import React from "react";
import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { MARKETPLACE_ROUTE } from "constants/route-constants";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { MarketPlaceType } from "@vahak/core/dist/constants";
import MarketPlacePage, {
    botTest,
    getLorryTypeFromParam,
    MarketPlacePageProps
} from "../../components/marketplace-new/marketplace-page-v2/MarketPlacePage";

function MarketplaceByType({ ...props }: MarketPlacePageProps) {
    return <MarketPlacePage {...props} />;
}

export default MarketplaceByType;

export const getServerSideProps: GetServerSideProps<MarketPlacePageProps> = async (context: any) => {
    console.time("mkt_server");
    const { req, query } = context;
    const userAgent = req.headers["user-agent"];
    const isBot = botTest(req.headers["user-agent"]);
    const { cookies } = req;

    const companyId = cookies.ci;
    const isPhoneVerified = cookies.vv;
    const serviceType = cookies.st;

    let srcParam = "";
    let destParam = "";

    const lorryType = getLorryTypeFromParam(query.l)?.map((v) => v.id) ?? [];

    const locationParam = query?.params?.[1]?.split("to-") || "";
    if (locationParam) {
        srcParam = locationParam[0].replace(/-/g, " ");
        destParam = locationParam.length === 2 ? locationParam[1].replace(/-/g, " ") : "";
    }

    if (
        (query.params?.[0] !== MarketPlaceType.LOAD && query.params?.[0] !== MarketPlaceType.LORRY) ||
        query.params?.length >= 3
    ) {
        return {
            notFound: true
        };
    }

    console.timeEnd("mkt_server");
    return {
        props: {
            ...(await serverSideTranslations(context.locale, ["common", "marketplace"])),
            ...({
                marketplaceType: query.params?.[0],
                urlPage: parseInt(query.p as string) || null,
                urlSrc: srcParam,
                urlDest: destParam,
                urlFilters: lorryType
                    ? {
                          lorryTypeIds: lorryType
                      }
                    : null,
                data: null,
                isMobile: userAgent?.includes("Mobile"),
                isLoggedIn: !!companyId,
                isPhoneVerified: !!isPhoneVerified,
                serviceType: (serviceType as CompanyServiceType) || null,
                isBot,
                route: MARKETPLACE_ROUTE,
                embeddedWithProfile: false
            } as MarketPlacePageProps)
        }
    };
};
